<template>
	<div id="DownloadApp">
		<CommonHeader />
		<div class="dowd-wrap">
			<div class="content">
				<div class="cleft">
					<img src="http://cdn.yunhjc.com/iphone.png" />
				</div>
				<div class="cright">
					<div class="title">
						<h1>轻重时间管理工具</h1>
					</div>
					<div class="explain">
						<p>
							记录下来需要做的一些事务，根据事务的重要性来进行等级的划分，让自己
							的事务更加有规划的去完成，根据事务的紧急、不紧急的进行规划管理（把
							所有能想到的事务，全部写上去，平时花了时间的东西）
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="bot">
			<div class="cdowd">
				<div class="bg"></div>
				<img src="../assets/images/app.png" alt="" />
			</div>
			<div class="copyright">
				©2020-2021轻重缓急All Rights Reserved
				<a href="https://beian.miit.gov.cn/" target="_blank"
					>粤ICP备15072947号-6
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader'

export default {
	name: 'DownloadApp',
	components: { CommonHeader },
}
</script>

<style lang="less" scoped>
#DownloadApp {
	position: relative;

	.bot {
		width: 100%;
		height: 25vh;
		background: #70ccff;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: -1;
		.cdowd {
			width: 245px;
			height: 245px;
			border-radius: 10px;
			position: relative;
			margin: auto;
			left: 35px;
			bottom: 122px;
			background: #fff;
			display: flex;
			.bg {
				border-radius: 10px 10px 0 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 50%;
				background: #70ccff;
				z-index: 1;
			}
			img {
				display: block;
				margin: auto;
				width: 226px;
				height: 226px;
				z-index: 9;
			}
		}
		.copyright {
            margin-top: 0;
			box-sizing: border-box;
			text-align: center;
			padding: 30px 0;
			font-size: 14px;
		}
	}
}

.dowd-wrap {
	width: 1200px;
	height: calc(100vh - 50px);
	margin: auto;
	display: flex;
	align-items: center;
	.content {
		height: 680px;
		display: flex;

		.cleft {
			flex: 1;
			img {
				width: 100%;
				/*height: 100%;*/
			}
		}

		.cright {
			flex: 2;
			margin-left: 134px;
			.title {
				margin-top: 90px;
				h1 {
					color: #70ccff;
					font-size: 36px;
				}
			}

			.explain {
				margin-top: 30px;
				font-size: 24px;
				color: #333333;
				text-align: justify;
				p {
					line-height: 40px;
				}
			}
		}
	}
}
</style>
